.emptyListWrapper {
  width: 100%;
  height: 100%;
  .emptyListBox {
    width: 100%;
    height: 100%;
    color: #737373;
    img {
      width: 80px;
      margin: 80px auto 0;
    }
    div {
      margin: 0px auto 70px;
      width: 210px;
      text-align: center;
    }
  }
}
