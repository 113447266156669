@use 'styles/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.icon {
  color: colors.$bronto;
}
