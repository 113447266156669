@use 'styles/colors';
@use 'styles/media';

.showInfoButton {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: underline;
  svg {
    margin-left: 6px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  @include media.for-tablet-landscape-up {
    flex-direction: row;
  }
  .applicationImage {
    margin: 20px;
    max-height: none;
    object-fit: cover;
    box-shadow: 0px 0px 18px 0px rgba($color: #000000, $alpha: 0.25);
    @include media.for-tablet-landscape-up {
      max-height: 600px;
      max-width: 50%;
    }
    @include media.for-phone-only {
      margin: 10px 0;
    }
  }
}

// .questionsBox {
//   width: 100%;
//   padding: 1.5rem;
//   @include media.for-phone-only {
//     padding: 0.1rem;
//   }
//   .question {
//     background-color: colors.$gray-100;
//     width: 100%;
//     justify-content: space-between;
//     display: flex;
//     align-items: center;

//     .questionInputGroup {
//       display: flex;
//       align-items: center;
//       gap: 8px;
//       width: 90%;
//       justify-content: space-between;
//     }
//     .questionInput {
//       width: 70%;
//       @include media.for-phone-only {
//         width: 100%;
//       }
//     }
//     input {
//       width: 100%;
//     }
//     @include media.for-phone-only {
//       flex-direction: column;
//       row-gap: 8px;
//       width: 100%;
//       margin-bottom: 0.5rem;
//     }

//     .delete {
//       @include media.for-phone-only {
//         width: 40px;
//         padding: 4px;
//         span {
//           display: none;
//         }
//       }
//     }
//   }
//   .questionsAddNew {
//     display: flex;
//     gap: 1rem;
//     align-items: center;
//     justify-content: center;
//     @include media.for-phone-only {
//       margin-top: 0.5rem;
//     }
//   }
// }

