@use 'styles/colors';

.container {
  display: inline-block;
  position: relative;
  margin: 0 2px;
  margin-top: calc((0.75rem + 4px) / 2);
}

.blockContainer {
  display: block;
  width: 100%;
}

.inputWrapper {
  &.isError {
    input,
    select,
    textarea,
    // match react-select
    // it's a bit hacky, but will do
    [class$=-control] {
      border-color: colors.$error;
    }
    :global .quill,
    :global .customInput > div {
      border-color: colors.$error;
    }
  }
}

.errorMessage {
  color: colors.$error;
  font-size: 0.75rem;
  margin-bottom: 4px;
  height: 1rem;
}
