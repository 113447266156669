@use 'styles/colors';

.overlay {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal {
  background-color: white;
  width: 1000px;
  position: fixed;
  top: 40px;
  left: calc(50% - 400px);
  display: flex;
  box-shadow: 0px 0px 18px 0px rgba($color: #000000, $alpha: 0.75);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  .content {
    width: 100%;

    .modalTitleBox {
      border-bottom: 1px solid rgb(233, 231, 231);
      padding: 20px 40px;
    }

    .modalFormBox {
      padding: 20px 40px 80px;

      .infoBox {
        padding: 20px;
        background-color: colors.$gray-100;
      }

      textarea {
        width: 100%;
        display: block;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

.usersList {
  margin-top: 20px;
}

.table {
  tbody {
    display: table;
    width: 100%;
  }
  td {
    padding: 0.5rem;
    white-space: nowrap;
  }
  display: block;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  .displayName {
    max-width: 350px;
    overflow-x: auto;
  }

  .myUsers {
    background-color: colors.$bronto-super-light;
  }
  .otherUsers {
    background-color: colors.$gray-100;
  }
  .birthsdayButton {
    display: inline;
  }
  .birthsdayInput {
    margin-right: 20px;
  }
}

.errorBirthdate {
  background-color: colors.$error-light !important;
}

.successBirthdate {
  background-color: colors.$bronto-super-light !important;
}

.collapseUserRow {
  cursor: pointer;
  &:hover {
    background-color: colors.$bronto-300;
  }
}

.smallUserDuplicateText {
  width: 400px;
  font-size: 14px;
  white-space: pre-wrap;
}
.smallUserDuplicateButton {
  font-size: 14px;
}

.iconInline {
  display: inline;
  margin-right: 5px;
}