.container {
  display: flex;
  justify-content: left;
  gap: 3rem;

  .pages {
    display: inline-flex;
    align-items: center;
    button {
      padding: 0;
    }
    gap: 1rem;

    .active {
      text-decoration: underline 3px solid black;
    }
  }

  .exact {
    display: inline-flex;
    align-items: center;
    gap: 1rem;

    input[type='submit'] {
      padding-left: 1rem;
      padding-right: 1rem;
      border: 2px solid #ebeff4;
    }
  }
}
