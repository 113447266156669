@use 'styles/media';
@use 'styles/colors';

input:not([type]),
input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='time'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='datetime-local'],
textarea,
select {
  border: 2px solid colors.$bronto-light; // #ebeff4;
  border-radius: 6px;
  padding: 8px 12px;

  &::placeholder {
    color: rgb(166, 166, 166);
    font-size: 0.875rem;
  }
}

input[type='number'] {
  appearance: textfield;
}

// button,
input[type='submit'],
input[type='reset'] {
  border-radius: 10px;
  padding: 5px 2rem;
  cursor: pointer;
  text-align: center;
}

.radioLabel {
  font-size: 1.1rem;
  line-height: 1.1;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.7em;
  margin-top: 1em;

  margin-right: 0.8em;
  @include media.for-phone-only {
    margin-right: 0;
  }

  input {
    cursor: pointer;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.3em;
    height: 1.3em;
    border: 0.15em solid colors.$bronto-light;
    border-radius: 50%;
    /* optically tune the alignment of the input in relation to the label text. */
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    &::before {
      content: '';
      width: 0.7em;
      height: 0.7em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      /* used instead of bg-color, to enable printing */
      box-shadow: inset 1em 1em colors.$bronto;
      /* to work in "Windows High Contrast Mode" */
      background-color: colors.$bronto;
    }

    &:checked {
      &::before {
        transform: scale(1);
      }
    }
  }
}

.checkboxDesription, .checkboxLabelWrapper {
  display: inline-block;
  align-items: center;
}

.checkboxDesription {
  margin-right: 2em;
  font-size: 1.2rem;
  font-weight: 600;
}

.checkboxLabel {
  display: grid;
  padding: 12px;
  padding-right: 6px;
  overflow: hidden;
  grid-template-columns: 1em auto;
  gap: 1em;
  align-items: center;
  width: 100%;
  margin: 1rem 1rem 0 0;
  cursor: pointer;
  @include media.for-tablet-landscape-up {
    gap: 0.7em;
    width: unset;
    margin: 0;
  }

  svg {
    width: 30px;
    height: 30px;
    @include media.for-tablet-landscape-up {
      width: 50px;
      height: 50px;
      transform: translateX(-5px);
    }
    path {
      fill: colors.$bronto;
      stroke: colors.$bronto;
      stroke-width: 1px;
    }
  }

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
  }
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em colors.$bronto;
  box-shadow: colors.$bronto;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:disabled {
  --form-control-color: colors.$gray-200;
  color: colors.$gray-300;
  cursor: not-allowed;
}

.labelCheckboxTag {
  border: 3px solid colors.$bronto-light;
  background-color: colors.$bronto-light;
  border-radius: 40px;
  color: colors.$bronto;
  overflow: hidden;

  &:has(input[type='checkbox']:checked) {
    background-color: colors.$bronto;
    color: white;
    svg {
      path {
        fill: white;
        stroke: white;
      }
    }
  }
  &:hover {
    border: 3px solid colors.$bronto;
    transition: 120ms ease-in-out;
  }
}
