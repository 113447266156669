@use 'styles/colors';
@use 'styles/media';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 2rem;
    font-weight: bold;
    font-variant-caps: small-caps;
  }

  .infoBox {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 2rem;
    > * {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .image {
    max-height: 16rem;
    width: 26rem;
    max-width: 100%;
    object-fit: cover;
  }

  .imageMissing {
    height: 16rem;
    width: 26rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: colors.$gray-100;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    > a {
      color: black;
      background-color: colors.$bronto-light;
      &:hover {
        background-color: white;
      }
    }
  }

  // this piece is unused
  // it's for for displaying raw data
  // .data {
  //   margin: 1rem 0;
  //   padding: 1rem;
  //   max-width: 100%;
  //   overflow: scroll;
  //   background-color: #eee;
  // }

  .dataHeader {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .feedbackLink {
    width: 900px;
    max-width: 100%;
  }

  .infoBoxDetail {
    overflow: hidden;
    width: 900px;
    max-width: 100%;
    display: flex;
    margin: 1rem 0;
    @include media.for-phone-only {
      flex-direction: column;
    }
    .imageWrapper {
      position: relative;
      border-right: 2px solid colors.$bronto;
      flex-basis: 40%;
      @include media.for-phone-only {
        flex-basis: 100%;
        border-right: none;
        border-bottom: 2px solid colors.$bronto;
      }
      .mainImage {
        object-fit: cover;
        height: 100%;
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        position: absolute;
        bottom: 0;
        right: 0;
        .tag {
          text-transform: lowercase;
          padding: 0.325rem 0.65rem;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 2rem;
        }
        margin: 0.5rem 1rem;
      }
    }

    .table {
      margin: 1.3rem auto;
    }

    border-radius: 32px;
    background-color: colors.$gray-100;
    table {
      th {
        padding-right: 1rem;
        padding-bottom: 0.325rem;
        text-align: right;
      }
      th,
      td {
        vertical-align: top;
        font-size: 1.1rem;
      }
    }
  }

  .eventInfo {
    margin-bottom: 50px;
    .eventInfoNarrow {
      max-width: 700px;
      margin: auto;
      &:nth-child(1){
        margin: 50px auto 0 auto;
      }
    }
    table {
      margin: auto;
      width: 100%;

      tr {
        border-bottom: 2px solid #eaeaea40;
      }

      th {
        text-align: right;
        padding: 10px;
        font-weight: 500;
        width: 30%;
        @include media.for-phone-only {
          min-width: 150px;
        }
        @include media.for-tablet-portrait-up {
          min-width: 200px;
        }
        @include media.for-tablet-landscape-up {
          min-width: 250px;
        }
      }
      td {
        padding: 10px;
      }
    }
  }

  .invitationTexts {
    width: 90%;
    margin: 50px auto;
    @include media.for-phone-only {
      width: 95%;
    }

    header {
      font-size: 1.25rem;
      margin: 1.3rem 0 0.65rem;
      font-weight: 700;
      text-align: center;
    }

    .invitationText {
      margin: 1.3rem 0 0.65rem;
    }
    h3,
    .inlineHeader {
      font-size: 1.25rem;
      margin: 1.3rem 0 0.65rem;
      font-weight: 700;
    }

    .inlineHeaderSmall {
      font-size: 1.15rem;
      font-weight: 700;
    }

    section {
      ul {
        list-style-type: disc;
        margin-left: 1.5rem;
      }

      ol {
        list-style-type: decimal;
        margin-left: 1.5rem;
      }
    }
  }

  .imageList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 50px;

    .image {
      border-radius: 20px;
    }
  }
}

// a little color detail when it's opportunity
:global .opportunitySection {
  :local .infoBoxDetail {
    :local .imageWrapper {
      border-bottom-color: colors.$opportunities;
    }
  }
}
