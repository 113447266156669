.toggleButton {
  margin-left: -2.2rem;
  padding: 0;
  color: gray;
}

.wrapper {
  white-space: nowrap;
  position: relative;
}

// just to align button in the input
.innerWrapper {
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  padding-right: 2.2rem !important;
}
