.container {
  padding: 1rem;
  p {
    color: lightgray;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem 2rem;
    a {
      color: gray;
      &:hover {
        color: black;
      }
    }
  }
}
