// inspired by https://stackoverflow.com/a/24979148
@use 'styles/media';

html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 2000px;
  margin: 0 auto;

  & > .row {
    // border: 1px dotted grey; // for debugging
    &.header {
      flex: 0 1 auto;
      /* The above is shorthand for:
      flex-grow: 0,
      flex-shrink: 1,
      flex-basis: auto
      */
      padding: 0.5rem 0 0;
    }
    &.content {
      flex: 1 1 auto;
    }

    &.footer {
      // flex: 0 1 40px; // this is how to make a component of fixed height
      flex: 0 1 auto;
    }
  }

  &.page {
    & > .row {
      &.content {
        // @include media.for-medium-desktop-up {
        //   margin: 0 70px;
        // }

        @include media.for-phone-only {
          margin: 0 4px;
        }

         
        @include media.for-tablet-portrait-up {
          margin: 0 10px;
        }

        @include media.for-tablet-landscape-up {
          margin: 0 20px;
        }
      }
    }
  }
}

// .box.page {
//   .noPageMargin {
//     @include media.for-tablet-portrait-up {
//       margin-left: -70px;
//       margin-right: -70px;
//     }
//   }
// }
