@use 'styles/colors';
.binIconContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 100%;

  &:hover {
    .circle {
      transform: scale(1);
    }
  }

  .iconHead {
    position: relative;
    color: black;
  }
}

.circle {
  position: absolute;
  top: 0;
  right: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: colors.$gray-200;
  transition: all 0.3s ease;
  transform: scale(0);
}

.disabledCell {
  pointer-events: none;
  cursor: not-allowed !important;
  color: colors.$gray-200;
  svg {
    path {
      fill: colors.$gray-200;
    }
  }
}
