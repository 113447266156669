.emailList {
    .emailListTitle {
        font-weight: 600;
    }
    
    .emailListContent {
        max-height: 300px;
        min-height: 42px;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #d9d9d9;
    }
  
    .emailListTitle, .emailListContent {
        padding: 0.5rem;
    }

    margin-bottom: 21px;
}  