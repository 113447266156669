ul,
ol {
  & > li > .container {
    padding-left: 0;
  }
}

.container {
  // padding: 1rem;
  padding-left: 1.5rem;

  .inlineTitle {
    font-weight: 600;
  }

  .withFormattedHTML {
    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }

    ol {
      list-style-type: decimal;
      margin-left: 1.5rem;
    }
  }

  ul {
    // margin-bottom: 1rem;
    margin-left: 1.5rem;
    list-style-type: disc;
  }

  // format h1 - h6
  @for $i from 1 through 6 {
    h#{$i} {
      // font-size: 2rem - $i * 0.2rem;
      font-size: 1rem;
      font-weight: 600;
    }
  }

  // give a headspace to every block on first level
  .block {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
  .container {
    .block {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
