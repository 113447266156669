@use 'styles/media';

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profilePicture{
    margin: 2rem 0 1rem
  }

  h2.subHeader{
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
  }

  h3{
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
  }

  table.horizontalTable {
    margin: auto;
    tr {
      border-bottom: 2px solid #eaeaea40;
    }
    th {
      padding-right: 1rem;
      text-align: right;
      font-weight: 500;
      min-width: 250px;
      @include media.for-phone-only{
        min-width: 150px;
      }
    }
    th,
    td {
      vertical-align: top;
      padding: 10px;
      line-height: 1.5;
    }
    td.oneCellRow{
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 1rem;
    }
    td.oneCellRowSmall{
      text-align: center;
      font-size: 1.1rem;
      font-weight: 600;
      padding: 1rem;
    }
    .bold{
      font-weight: 500;
    }
    .tableDiv{
      margin-bottom: 1rem;
    }
    .tableDiv:nth-last-of-type(1){
      margin-bottom: 0;
    }
  }

  .buttonGroup{
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  // copy-pasted from ViewEvent.module.scss
  .data {
    margin: 1rem 0;
    padding: 1rem;
    max-width: 100%;
    overflow: scroll;
    background-color: #eee;
  }
}
