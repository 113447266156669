.button {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-decoration: underline;
    gap: 6px;
}

.info {
  ol {
    list-style: decimal;
    padding-left: 2rem;
  }

  li {
    margin: 5px 0;
  }
}