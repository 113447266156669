@use 'styles/colors';

.behaviour {

  background-color: colors.$error-light;
  padding: 0.5rem 1rem;

  .icon {
    display: inline;
    color: colors.$error;
  }
}
