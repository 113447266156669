@use 'styles/colors';
@use 'styles/media';

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1.7rem;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
}

.tableContainer {
  overflow-x: auto;
  transform: rotateX(.5turn);

  table {
    transform: rotateX(.5turn);
  }

  @include media.for-tablet-portrait-up {
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
    }

    th:last-child,
    td:last-child {
      position: sticky;
      right: 0;
    }
  }
}

.emptyList {
  transform: rotateX(.5turn);
}

.table {
  table-layout: auto;
  width: 100%;
  margin-top: 16px;
  @include media.for-phone-only {
    max-width: inherit;
    overflow: auto;
    display: block;
  }
  th,
  td {
    padding: 0.5rem;
  }
  thead {
    th {
      border-bottom: 1px solid #737373;
      text-align: left;
      background-color: white;
      svg {
        display: inline-block;
      }

      &:nth-last-child(1),
      &:nth-last-child(2) {
        text-align: center;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  tbody {
    .highlightedRow {
      background-color: colors.$gray-100;
    }
    td {
      cursor: pointer;
      justify-content: center;

      &:nth-last-child(2),
      &:last-child {
        width: 50px;
      }
      svg {
        display: inline-block;
      }
    }
  }

  tbody tr td {
    border-bottom: 1px solid #d9d9d9;
  }

  tbody tr td:not(.oneCellRow) {
    background-color: white;
    white-space: nowrap;
  }

  &.verticalLine1 {
    tbody tr td:first-child {
      border-right: 1px solid #d9d9d9;
      text-align: center;
    }
  }
}

.actionCell {
  display: flex;
  gap: 7px;
  margin: 0 5px;
}

.paidForCheckbox {
  gap: 0;
  padding: 5px;
  margin-right: 5px;
}

.paidForHead {
  margin-left: 5px;
  margin-right: 10px;
}

.applicationWithParticipant {
  color: colors.$gray-300;
}

.applicationAddedTag {
  color: colors.$bronto;
  font-weight: 600;
}

.showUserApplicationNameBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .addUserButtons {
    display: flex;
    flex-direction: row;
  }
}

.QAContainer {
  margin: 1.2rem 0;
}

.participantsContainer {
  width: 100%;
  border: 2px solid rgb(240, 238, 238);
  margin: 50px auto 0;

  @include media.for-medium-desktop-up {
    width: 80%;
  }

  .ListContainer {
    @include media.for-phone-only {
      padding: 10px;
    }
    @include media.for-tablet-portrait-up {
      padding: 20px;
    }
    @include media.for-tablet-landscape-up {
      padding: 40px;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 16px;
      gap: 12px;
      @include media.for-phone-only {
        flex-wrap: wrap;
      }

      .excelButtons {
        display: flex;
        gap: 12px;
      }
    }

    .existingParticipantContainerWrapper {
      margin-top: 12px;
      [class$=-control]{
        border-width: 2px;
        border-color: black;
      }
      .existingParticipantContainer{
        overflow-x: auto;
        @include media.for-tablet-portrait-up {
          thead th, 
          tbody td {
            &:first-child
            {
              position: sticky;
              left: 0;
              background-image: linear-gradient(to right, #ffffff 0%, #ffffff  99.7%, #d9d9d9 100%);
            }
            &:last-child {
              position: sticky;
              right: 0;
            }
          }
        }
      }
    }

    .lightUp {
      animation: sampleanimation 7s;
      animation-iteration-count: 1;
    }
  }

  .StatsContainer {
    @include media.for-tablet-portrait-up {
      padding: 20px 20px 0 20px;
     }
     @include media.for-tablet-landscape-up {
      padding: 40px 40px 0 40px;
     }
    @include media.for-phone-only {
     padding: 10px 10px 0 10px;
    }
  }
}

.addedUserBlock {
  margin-top: 40px;
}

@keyframes sampleanimation {
  0% {
    background-color: white;
  }
  10% {
    background-color: colors.$bronto-light;
  }
  25% {
    background-color: colors.$bronto-light;
  }
  50% {
    background-color: colors.$bronto-light;
  }
  75% {
    background-color: white;
  }
}

.oneCellRow {
  z-index: 0; // create stacking context

  td {
    border-left: 0px !important;
    border-right: 0px;
    background-color: colors.$gray-200 !important;
    font-weight: 600;
  }

  td:first-child {
    z-index: 1;
    position: sticky;
    left: 0;
  }

  td:not(:first-child) {
    position: relative !important;
  }
}

.iconHead {
  width: 16px;
  path {
    fill: black;
  }
}

.centeredTableBlock {
  margin: 0 auto;
  color: black;
}

.addParticipantModal {
  z-index: 10;
}

.org {
  background-color: #f5b942;
  border: 3px solid #f5b942;
}

.orgTitle {
  background-color: black;
  border: 3px solid black;
}

.org, .orgTitle {
  border-radius: 8px;
  color: white;
  padding: 0 4px;
}

.boundedCell {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
