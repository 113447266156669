@use 'styles/colors';

.addButton {
  height: 10rem;
  width: 10rem;
  background-color: colors.$bronto-light;
  color: colors.$bronto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  svg {
    color: white;
    scale: 1;
    transition: 320ms ease-in-out;
  }
  &:hover {
    svg {
      scale: 1.2;
      transition: 320ms ease-in-out;
    }
  }
}

.imageWrapper {
  cursor: pointer;
  position: relative;
  .image {
    height: 10rem;
    cursor: pointer;
  }
  .editOverlay {
    display: none;
  }
  &:hover .editOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0009;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.imageList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: .5rem 0;

  .imageItem {
    position: relative;

    .toolbar {
      position: absolute;
      top: -0.6rem;
      right: -0.6rem;
      display: flex;
      gap: 2px;
    }

    .button {
      padding: 0.5rem;
      border-radius: 2rem;
    }

    .removeButton {
      background-color: pink;
      &:hover {
        background-color: red;
      }
    }

    .downloadButton {
      background-color: lightgreen;
      &:hover {
        background-color: green;
      }
    }
  }
}

.opportunitiesTheme {
  color: colors.$opportunities-500;
  background-color: colors.$opportunities-light;
}
