@use 'styles/colors';
@use 'styles/media';

.questionsBox {
  width: 100%;
  max-width: 925px;

  .questionList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: stretch;
  }

  .question {
    padding: 1rem 1.5rem;
    background-color: colors.$gray-100;

    .questionInputGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 1rem;

      @include media.for-phone-only {
        flex-wrap: wrap;
      }

      .questionInput {
        flex: auto;

        input {
          width: 100%;
        }
      }
      .typeInput {
        width: 130px;
        position: relative;
        select {
          width: 100%;
        }
      }
      .questionRequired {
        display: inline-flex;
        width: unset;
        margin: unset;
        padding: unset;
        column-gap: 0.5rem;
        @include media.for-phone-only {
          flex-direction: column;
          // order: -1;
          font-size: 0.8rem;
          gap: 0.25rem;
        }
      }
    }
  }

  .addQuestionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$gray-100;
    padding: 1rem;
    width: 100%;
    gap: 0.5rem;
  }
}

.delete {
  background-color: transparent !important;
  color: colors.$error;
  font-size: 1.5rem;
  @include media.for-phone-only {
    // width: 100%;
  }
}

.options {
  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: '';
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    flex-basis: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #f8f8f8;
    border: 1px solid lightgray;
    margin-right: 0.5rem;
  }

  &.radio {
    li::before {
      border-radius: 50%;
    }
  }

  .option {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 1rem;

    & > div {
      flex: 1;
      input {
        width: 100%;
      }
    }
  }

  .addOptionButton {
    background-color: unset;
    padding: 0;
  }
}